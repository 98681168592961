@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* フォント */
@font-face {
  font-family: 'NotoSansMedium';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Medium.woff) format("woff");
}

@font-face {
  font-family: 'NotoSansJPMedium';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Medium.woff) format("woff");
}

@font-face {
  font-family: 'NotoSansBold';
  font-style: normal;
  src: url(../../../common/font/NotoSans-ExtraBold.woff) format("woff");
}

@font-face {
  font-family: 'NotoSansExtraBold';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Black.woff) format("woff");
}

/* pluscampus news カテゴリータグ */
/*
 * 共通コンポーネントCSS
 */
* {
  padding: 0;
  margin: 0;
  font-family: 'NotoSansMedium', sans-serif;
  outline: none !important;
}

/*　アニメーション */
.text-link,
.text-link-about,
.text-link-sustainability,
.text-link-solution {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.text-link::after,
.text-link-about::after,
.text-link-sustainability::after,
.text-link-solution::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: #333;
  transition: transform 0.3s;
  transform: scale(0, 1);
  transform-origin: left top;
}

.text-link:hover::after,
.text-link-about:hover::after,
.text-link-sustainability:hover::after,
.text-link-solution:hover::after {
  transform: scale(1, 1);
}

.text-link.selected::after,
.text-link-about.selected::after,
.text-link-sustainability.selected::after,
.text-link-solution.selected::after {
  transform: scale(1, 1);
}

/* 通常フッター */
.footer-area .footer-wrapper .text-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.footer-area .footer-wrapper .text-link.external-link {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.footer-area .footer-wrapper .text-link.external-link::before {
  width: 14px;
  height: 14px;
  margin-top: 3px;
  margin-left: 5px;
  content: '';
  background: url("../img/css-img/clone_white.svg") 0 0/14px 14px no-repeat;
}

.footer-area .footer-wrapper .text-link::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: #fff;
  transition: transform 0.3s;
  transform: scale(0, 1);
  transform-origin: left top;
}

.footer-area .footer-wrapper .text-link:hover::after {
  transform: scale(1, 1);
}

.button {
  position: relative;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s;
}

@media all and (-ms-high-contrast: none) {
  .button {
    padding-top: 3px;
  }
}

body {
  min-width: 1240px;
  /* 改行 */
  /* ヘッダー */
  /* パンくずリスト */
  /* コンタクト */
  /* フッター */
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body {
    min-width: 0;
  }
}

@media screen and (max-width: 599px) {
  body {
    min-width: 0;
  }
}

body .br-sp {
  display: none;
}

body .br-tb {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .br-pc {
    display: none;
  }
  body .br-tb {
    display: block;
  }
  body .br-sp {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  body .br-sp {
    display: block;
  }
  body .br-tb {
    display: none;
  }
  body .br-pc {
    display: none;
  }
}

body hr {
  text-align: left;
}

body .header-area {
  position: fixed;
  top: 0;
  z-index: 20;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 107px;
  margin: 0 auto;
  list-style: none;
  transition: all 0.1s ease-out 0.1s;
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  body .header-area {
    z-index: 1000;
    flex-wrap: nowrap;
    height: 65px;
    padding: 0 0 0 40px;
    background: #fff;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area {
    z-index: 1000;
    flex-wrap: nowrap;
    height: 65px;
    padding: 0 0 0 40px;
    background: #fff;
  }
}

@media screen and (max-width: 599px) {
  body .header-area {
    z-index: 1000;
    flex-wrap: nowrap;
    height: 54px;
    padding: 0 0 0 20px;
    background: #fff;
  }
}

body .header-area .normal-logo {
  display: block;
}

body .header-area .white-logo {
  display: none;
}

body .header-area.jsBackgroundHeader {
  background: #fff;
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button {
    background: #cc0a1e;
  }
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button .nav-toggle i {
    background-color: #fff;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button {
    background: #cc0a1e;
  }
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button .nav-toggle i {
    background-color: #fff;
  }
}

@media screen and (max-width: 599px) {
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button {
    background: #cc0a1e;
  }
  body .header-area.jsBackgroundHeader .content-nav-sp .sp-nav-button .nav-toggle i {
    background-color: #fff;
  }
}

body .header-area .header-logo {
  width: 181px;
  margin-left: 40px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .header-logo {
    margin-left: 0;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .header-logo {
    margin-left: 0;
  }
}

body .header-area .header-logo .header-logo-img {
  width: 181px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .header-logo .header-logo-img {
    width: 166px;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .header-logo .header-logo-img {
    width: 138px;
  }
}

body .header-area .content-nav {
  display: flex;
  align-items: center;
  min-width: 800px;
  font-size: 14px;
}

body .header-area .content-nav .language-atention {
  position: relative;
  left: -12px;
  display: none;
  width: 277px;
  height: 41px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 41px;
  text-align: center;
  background: #fff;
  border: 1px solid #c3c3c3;
}

body .header-area .content-nav .header-content {
  display: flex;
  align-items: center;
  list-style: none;
}

body .header-area .content-nav .header-content .select-content {
  padding: 0 20px;
  line-height: 35px;
  cursor: pointer;
}

@media screen and (max-width: 1320px) {
  body .header-area .content-nav .header-content .select-content {
    padding: 0 12px;
  }
}

body .header-area .content-nav .header-content .select-content:last-of-type {
  padding-right: 40px;
}

body .header-area .content-nav .header-content .select-content .head-content {
  color: #292929;
  text-decoration: none;
}

body .header-area .content-nav .header-content .select-content .language {
  display: none;
}

body .header-area .content-nav .header-content .select-content .language_en {
  display: block;
}

body .header-area .content-nav .contact-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 35px;
  margin: 0 auto;
  margin-right: 40px;
  font-size: 14px;
  line-height: 35px;
  color: #fff;
  text-decoration: none;
  background: #cc0a1e;
  border: 1px solid #cc0a1e;
  border-radius: 100px;
}

body .header-area .content-nav .contact-btn:hover {
  color: #cc0a1e;
  background: #fff;
}

@media all and (-ms-high-contrast: none) {
  body .header-area .content-nav .contact-btn {
    padding-top: 3px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  body .header-area .content-nav {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .content-nav {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .content-nav {
    display: none;
  }
}

body .header-area .content-nav .language-btn-area {
  display: flex;
  flex-direction: column;
  width: 127px;
  height: 35px;
  padding: 0 12px 0 30px;
  margin: 36px 0;
  cursor: pointer;
  border-left: 2px solid #292929;
}

body .header-area .content-nav .language-menu {
  position: relative;
  width: 127px;
  line-height: 35px;
  text-align: center;
}

body .header-area .content-nav .language-menu::before {
  position: absolute;
  top: 9px;
  left: 6px;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  background: url("../img/css-img/icon_language.svg") top right/20px 20px no-repeat;
}

body .header-area .content-nav .language-menu::after {
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  display: block;
  width: 6px;
  height: 6px;
  content: '';
  border-right: 2px solid #292929;
  border-bottom: 2px solid #292929;
  transform: rotate(45deg);
}

body .header-area .content-nav .dropdown__lists {
  display: none;
}

body .header-area .content-nav .dropdown__lists.active {
  display: block;
  width: 127px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 11px 18px -4px rgba(0, 0, 0, 0.3);
}

body .header-area .content-nav .dropdown__list {
  position: relative;
  height: 30px;
  list-style-type: none;
  transition: all 0.3s;
}

body .header-area .content-nav .dropdown__list a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  color: #292929;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s;
}

body .header-area .content-nav a.active:hover {
  color: #cc0a1e;
}

body .header-area .content-nav .dropdown__list .check::before {
  position: absolute;
  top: 9px;
  left: 6px;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background: url("../img/css-img/icon_check.svg") top right/16px 16px no-repeat;
}

body .header-area .Header-backdrop-about {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 107px;
  pointer-events: none;
  visibility: hidden;
  background-color: #00000067;
  opacity: 0;
  transition: 0.3s ease-out;
}

body .header-area .Header-backdrop-about.Header-backdrop_visible {
  visibility: visible;
  opacity: 1;
}

body .header-area .Header-backdrop-sustainability {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 107px;
  pointer-events: none;
  visibility: hidden;
  background-color: #00000067;
  opacity: 0;
  transition: 0.3s ease-out;
}

body .header-area .Header-backdrop-sustainability.Header-backdrop_visible {
  visibility: visible;
  opacity: 1;
}

body .header-area .Header-backdrop-solution {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 107px;
  pointer-events: none;
  visibility: hidden;
  background-color: #00000067;
  opacity: 0;
  transition: 0.3s ease-out;
}

body .header-area .Header-backdrop-solution.Header-backdrop_visible {
  visibility: visible;
  opacity: 1;
}

body .header-area .dropdown_area.dropdown-about_hierarchy {
  display: none;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active {
  position: absolute;
  left: 50%;
  display: block;
  width: 100%;
  height: 330px;
  transition: all 0.5s;
  transform: translateX(-50%);
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .dropdown_area_white {
  position: absolute;
  top: 55px;
  left: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 44px 48px 39px;
  background: #fff;
  border-radius: 30px;
  transition: all 0.5s;
  transform: translateX(-50%);
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists {
  display: flex;
  height: 100%;
  padding: 0;
  font-size: 14px;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list {
  width: 208px;
  margin-right: 32px;
  color: #292929;
  list-style-type: none;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list:nth-child(5) {
  margin-right: 0;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list:nth-child(2) .text-link-list .head-content-img {
  border: 1px solid #c3c3c3;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list .text-link-list {
  display: block;
  text-decoration: none;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-img {
  width: 208px;
  height: 152px;
  vertical-align: bottom;
  border-radius: 20px;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.36;
  color: #292929;
  text-align: left;
  text-decoration: none;
}

body .header-area .dropdown_area.dropdown-about_hierarchy.active .head-content_lists .head-content_list .text-link-list:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy {
  display: none;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active {
  position: absolute;
  left: 50%;
  display: block;
  width: 100%;
  height: 330px;
  cursor: default;
  transition: all 0.5s;
  transform: translateX(-50%);
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .dropdown_area_white {
  position: absolute;
  top: 55px;
  left: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 44px 48px 39px;
  background: #fff;
  border-radius: 30px;
  transition: all 0.5s;
  transform: translateX(-50%);
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists {
  display: flex;
  height: 100%;
  padding: 0;
  font-size: 14px;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list {
  width: 208px;
  margin-right: 32px;
  color: #292929;
  list-style-type: none;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list:nth-child(2) {
  margin-right: 0;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list .text-link-list {
  display: block;
  text-decoration: none;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-img {
  width: 208px;
  height: 152px;
  vertical-align: bottom;
  border-radius: 20px;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-img.border {
  border: 1px solid #c3c3c3;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.36;
  color: #292929;
  text-align: left;
  text-decoration: none;
  letter-spacing: -0.15px;
}

body .header-area .dropdown_area.dropdown-sustainability_hierarchy.active .head-content_lists .head-content_list .text-link-list:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy {
  display: none;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active {
  position: absolute;
  left: 50%;
  display: block;
  width: 100%;
  height: 330px;
  cursor: default;
  transition: all 0.5s;
  transform: translateX(-50%);
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .dropdown_area_white {
  position: absolute;
  top: 55px;
  left: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 44px 48px 39px;
  background: #fff;
  border-radius: 30px;
  transition: all 0.5s;
  transform: translateX(-50%);
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists {
  display: flex;
  height: 100%;
  padding: 0;
  font-size: 14px;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list {
  width: 208px;
  margin-right: 32px;
  color: #292929;
  list-style-type: none;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list:nth-child(3) {
  margin-right: 0;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list .text-link-list {
  display: block;
  text-decoration: none;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-img {
  width: 208px;
  height: 152px;
  vertical-align: bottom;
  border-radius: 20px;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-img.border {
  border: 1px solid #c3c3c3;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list .text-link-list .head-content-text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.36;
  color: #292929;
  text-align: left;
  text-decoration: none;
}

body .header-area .dropdown_area.dropdown-solution_hierarchy.active .head-content_lists .head-content_list .text-link-list:hover {
  opacity: 0.5;
  transition: all 0.3s;
}

body .header-area .content-nav-sp {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .language-btn-area-sp.show {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .language-btn-area-sp.show {
    display: none;
  }
}

body .header-area .language-btn-area-sp {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .language-btn-area-sp {
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 54px;
    margin-left: auto;
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .language-btn-area-sp {
    display: flex;
    flex-direction: column;
    width: 110px;
    height: 54px;
    margin-left: auto;
    font-size: 12px;
    text-align: center;
  }
}

body .header-area .language-btn-area-sp .language-menu {
  position: relative;
  line-height: 54px;
  text-align: center;
}

body .header-area .language-btn-area-sp .language-menu::before {
  position: absolute;
  top: 20px;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background: url("../img/css-img/icon_language.svg") top right/16px 16px no-repeat;
}

body .header-area .language-btn-area-sp .language-menu::after {
  position: absolute;
  top: calc(50% - 5px);
  right: 5px;
  display: block;
  width: 6px;
  height: 6px;
  content: '';
  border-right: 2px solid #292929;
  border-bottom: 2px solid #292929;
  transform: rotate(45deg);
}

body .header-area .language-btn-area-sp .dropdown__lists {
  display: none;
}

body .header-area .language-btn-area-sp .dropdown__lists.active {
  display: block;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 11px 18px -4px rgba(0, 0, 0, 0.3);
}

body .header-area .language-btn-area-sp .dropdown__list {
  position: relative;
  height: 36px;
  list-style-type: none;
  transition: all 0.3s;
}

body .header-area .language-btn-area-sp .dropdown__list a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  color: #292929;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s;
}

body .header-area .language-btn-area-sp a.active:hover {
  color: #cc0a1e;
}

body .header-area .language-btn-area-sp .dropdown__list .check::before {
  position: absolute;
  top: 9px;
  left: 6px;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  background: url("../img/css-img/icon_check.svg") top right/16px 16px no-repeat;
}

body .header-area .language-btn-area-sp .language-atention {
  position: relative;
  left: -12px;
  display: none;
  width: 145px;
  padding: 7px;
  margin-top: 7px;
  font-size: 12px;
  text-align: left;
  background: #fff;
  border: 1px solid #c3c3c3;
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  body .header-area .content-nav-sp {
    position: relative;
    display: block;
    cursor: pointer;
  }
  body .header-area .content-nav-sp .sp-nav-button {
    display: relative;
    width: 65px;
    height: 65px;
    transition: all 0.1s ease-out 0.1s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 19px;
    height: 15px;
    transform: translate(-50%, -50%);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #707070;
    border-radius: 2px;
    transition: transform 0.5s, opacity 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(1) {
    top: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(2) {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(3) {
    bottom: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(1) {
    transform: translateY(5px) rotate(-45deg);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(2) {
    opacity: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav {
    position: fixed;
    top: 46px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav.show {
    visibility: visible;
    opacity: 1;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list {
    position: fixed;
    top: 65px;
    left: 50%;
    z-index: 1001;
    width: 100%;
    height: 336px;
    overflow: scroll;
    transform: translateX(-50%);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content {
    z-index: 1001;
    width: 100%;
    border-bottom: solid 1px #fff;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link {
    position: relative;
    display: block;
    padding-left: 19px;
    font-size: 15px;
    font-weight: bold;
    line-height: 44px;
    color: #fff;
    text-decoration: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link span {
    font-size: 15px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language_en {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link.sub-menu {
    margin-left: 28px;
    font-weight: normal;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    margin-top: 2px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list {
    list-style-type: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .contact-btn {
    position: fixed;
    top: 430px;
    left: 50%;
    z-index: 1001;
    width: calc(100% - 30px);
    max-width: 208px;
    height: 34px;
    font-size: 11px;
    font-weight: bold;
    line-height: 32px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: #cc0a1e;
    border-radius: 17px;
    transform: translateX(-50%);
  }
  body .header-area .language {
    display: none;
  }
  body .header-area .language_en {
    display: block;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .header-area .content-nav-sp {
    position: relative;
    display: block;
    cursor: pointer;
  }
  body .header-area .content-nav-sp .sp-nav-button {
    display: relative;
    width: 65px;
    height: 65px;
    transition: all 0.1s ease-out 0.1s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 19px;
    height: 15px;
    transform: translate(-50%, -50%);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #707070;
    border-radius: 2px;
    transition: transform 0.5s, opacity 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(1) {
    top: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(2) {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(3) {
    bottom: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(1) {
    transform: translateY(5px) rotate(-45deg);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(2) {
    opacity: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav {
    position: fixed;
    top: 46px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav.show {
    visibility: visible;
    opacity: 1;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list {
    position: fixed;
    top: 65px;
    left: 50%;
    width: 100%;
    height: 336px;
    overflow: scroll;
    transform: translateX(-50%);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content {
    width: 100%;
    border-bottom: solid 1px #fff;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link {
    position: relative;
    display: block;
    padding-left: 19px;
    font-size: 15px;
    font-weight: bold;
    line-height: 44px;
    color: #fff;
    text-decoration: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link span {
    font-size: 15px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language_en {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link.sub-menu {
    margin-left: 28px;
    font-weight: normal;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    margin-top: 2px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list {
    list-style-type: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .contact-btn {
    position: fixed;
    top: 430px;
    left: 50%;
    width: calc(100% - 30px);
    max-width: 208px;
    height: 34px;
    font-size: 11px;
    font-weight: bold;
    line-height: 32px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: #cc0a1e;
    border-radius: 17px;
    transform: translateX(-50%);
  }
  body .header-area .language {
    display: none;
  }
  body .header-area .language_en {
    display: block;
  }
}

@media screen and (max-width: 599px) {
  body .header-area .content-nav-sp {
    position: relative;
    display: block;
    cursor: pointer;
  }
  body .header-area .content-nav-sp .sp-nav-button {
    display: relative;
    width: 54px;
    height: 54px;
    transition: all 0.1s ease-out 0.1s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 17px;
    height: 14px;
    transform: translate(-50%, -50%);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #707070;
    border-radius: 2px;
    transition: transform 0.5s, opacity 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(1) {
    top: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(2) {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle i:nth-child(3) {
    bottom: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(1) {
    transform: translateY(6px) rotate(-45deg);
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(2) {
    opacity: 0;
  }
  body .header-area .content-nav-sp .sp-nav-button .nav-toggle.show i:nth-child(3) {
    transform: translateY(-6px) rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav {
    position: fixed;
    top: 46px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
  }
  body .header-area .content-nav-sp .sp-nav.show {
    visibility: visible;
    opacity: 1;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list {
    position: fixed;
    top: 54px;
    left: 50%;
    width: 100%;
    height: 336px;
    overflow: scroll;
    transform: translateX(-50%);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content {
    width: 100%;
    border-bottom: solid 1px #fff;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link {
    position: relative;
    display: block;
    padding: 12px 0 12px 19px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link span {
    font-size: 15px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link .language_en {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-nav-content-link.sub-menu {
    margin-left: 28px;
    font-weight: normal;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-about.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-about.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-sustainability.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-sustainability.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 40px;
    display: block;
    width: 6px;
    height: 6px;
    margin-top: 2px;
    content: '';
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .sp-header-menu-solution.check::after {
    margin-top: 2px;
    transform: rotate(225deg);
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution {
    display: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_lists-solution.active {
    display: block;
    animation: fade-in 0.5s;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list {
    list-style-type: none;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list::before {
    position: absolute;
    top: 9px;
    left: 6px;
    display: none;
    width: 4px;
    height: 28px;
    content: '';
    background: #fff;
    border-radius: 3px;
  }
  body .header-area .content-nav-sp .sp-nav .sp-nav-list .sp-nav-content .header-menu_list.current .sp-nav-content-link::before {
    display: block;
  }
  body .header-area .content-nav-sp .contact-btn {
    position: fixed;
    top: 420px;
    left: 50%;
    width: calc(100% - 40px);
    height: 34px;
    font-size: 11px;
    font-weight: bold;
    line-height: 32px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: #cc0a1e;
    border-radius: 17px;
    transform: translateX(-50%);
  }
  body .header-area .language {
    display: none;
  }
  body .header-area .language_en {
    display: block;
  }
}

body .topic-path {
  height: 81px;
  padding: 0 0 0 237px;
  line-height: 81px;
  background: #dfe0e2;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .topic-path {
    height: 100%;
    padding: 0 0 0 20px;
    line-height: 72px;
  }
}

@media screen and (max-width: 599px) {
  body .topic-path {
    height: 100%;
    min-height: 40px;
    padding: 0 15px 0 20px;
    line-height: 40px;
  }
}

body .topic-path ul {
  display: flex;
  padding: 0;
  list-style-type: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .topic-path ul {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 599px) {
  body .topic-path ul {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .topic-path ul .topic-list {
    font-size: 18px;
  }
}

@media screen and (max-width: 599px) {
  body .topic-path ul .topic-list {
    height: auto;
    min-height: 40px;
    font-size: 13px;
    line-height: 40px;
  }
}

body .topic-path ul .topic-list + .topic-list::before {
  display: inline-block;
  margin: 0 12px;
  content: '▶';
}

body .topic-path ul .topic-list .topic-list-child {
  color: #292929;
  text-decoration: none;
  letter-spacing: 1px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .topic-path ul .topic-list .topic-list-child {
    height: 40px;
    font-size: 18px;
  }
}

@media screen and (max-width: 599px) {
  body .topic-path ul .topic-list .topic-list-child {
    letter-spacing: 0.1px;
  }
}

body .child-contact-wrapper {
  width: 1080px;
  margin: 0 auto;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .child-contact-wrapper {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper {
    width: 100%;
    text-align: center;
  }
}

body .child-contact-wrapper .main-title {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

body .child-contact-wrapper .main-title hr {
  width: 98px;
  height: 8px;
  margin: 10px auto 0;
  background: #292929;
  border: none;
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .main-title {
    margin: 0;
    font-size: 32px;
  }
  body .child-contact-wrapper .main-title hr {
    height: 4px;
    margin: 4px auto 0;
  }
}

body .child-contact-wrapper .contact-contents {
  margin: 43px auto 0;
  text-align: center;
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .contact-contents {
    width: 100%;
    margin: 29px auto 0;
  }
}

body .child-contact-wrapper .contact-contents .contact-contents-details {
  margin-top: 5px;
  font-size: 18px;
  line-height: 1.8;
  color: #3c3c3c;
  letter-spacing: 2.88px;
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .contact-contents .contact-contents-details {
    display: none;
  }
}

body .child-contact-wrapper .contact-contents .contact-contents-details-sp {
  display: none;
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .contact-contents .contact-contents-details-sp {
    display: flex;
    justify-content: center;
    margin: 21px auto 0;
    font-size: 14px;
    line-height: 2;
    color: #292929;
    text-align: left;
    letter-spacing: 0.56px;
  }
}

body .child-contact-wrapper .contact-contents .next-contact {
  display: block;
  width: 432px;
  height: 68px;
  margin: 23px auto 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 68px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  background: url("../img/css-img/arrows_white.svg") top 50% right 64px no-repeat #cc0a1e;
  border-radius: 60px;
}

body .child-contact-wrapper .contact-contents .next-contact:hover {
  color: #cc0a1e;
  background: url("../img/css-img/arrows_red.svg") top 50% right 64px no-repeat #fff;
  border: solid 1px #cc0a1e;
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .contact-contents .next-contact {
    width: calc(100% - 80px);
    height: 48px;
    margin: 30px auto 0;
    font-size: 16px;
    line-height: 48px;
    background: url("../img/css-img/arrows_white.svg") top 50% right 36px/24px 6px no-repeat #cc0a1e;
  }
  body .child-contact-wrapper .contact-contents .next-contact:hover {
    color: #cc0a1e;
    background: url("../img/css-img/arrows_red.svg") top 50% right 36px/24px 6px no-repeat #fff;
    border: solid 1px #cc0a1e;
  }
}

body .child-contact-wrapper .contact-contents .language {
  display: none;
}

body .child-contact-wrapper .contact-contents .language_en {
  display: block;
  margin-bottom: 43px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 2.88px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .child-contact-wrapper .contact-contents .language_en {
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  body .child-contact-wrapper .contact-contents .language_en {
    width: auto;
    padding: 0px 10px;
    margin-bottom: 0px;
  }
}

body .child-contact-wrapper .contact-contents .language_en-btn {
  display: block;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  white-space: nowrap;
}

body .footer-area {
  padding: 81px 0 10px;
  color: #fff;
  background: #1b1e24;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area {
    height: auto;
    padding: 36px 20px;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area {
    height: auto;
    padding: 36px 20px;
  }
}

body .footer-area .footer-wrapper {
  width: 1080px;
  margin: 0 auto;
}

body .footer-area .footer-wrapper .footer-logo {
  width: 180px;
  transition: all 0.3s;
}

body .footer-area .footer-wrapper .footer-logo:hover {
  opacity: 0.7;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper {
    width: 100%;
  }
  body .footer-area .footer-wrapper .footer-logo {
    width: 369px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper {
    width: 100%;
  }
  body .footer-area .footer-wrapper .footer-logo {
    width: 180px;
    margin-left: 20px;
  }
}

body .footer-area .footer-wrapper .service-list,
body .footer-area .footer-wrapper .brand-list,
body .footer-area .footer-wrapper .recruit-list,
body .footer-area .footer-wrapper .marine-tech-list,
body .footer-area .footer-wrapper .sustainability-list,
body .footer-area .footer-wrapper .conpany-list,
body .footer-area .footer-wrapper .news-list,
body .footer-area .footer-wrapper .sns-list,
body .footer-area .footer-wrapper .policy-en-list,
body .footer-area .footer-wrapper .policy-list {
  width: 182px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper .service-list,
  body .footer-area .footer-wrapper .brand-list,
  body .footer-area .footer-wrapper .recruit-list,
  body .footer-area .footer-wrapper .marine-tech-list,
  body .footer-area .footer-wrapper .sustainability-list,
  body .footer-area .footer-wrapper .conpany-list,
  body .footer-area .footer-wrapper .news-list,
  body .footer-area .footer-wrapper .sns-list,
  body .footer-area .footer-wrapper .policy-en-list,
  body .footer-area .footer-wrapper .policy-list {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #fff;
  }
  body .footer-area .footer-wrapper .service-list.pc-only-list,
  body .footer-area .footer-wrapper .brand-list.pc-only-list,
  body .footer-area .footer-wrapper .recruit-list.pc-only-list,
  body .footer-area .footer-wrapper .marine-tech-list.pc-only-list,
  body .footer-area .footer-wrapper .sustainability-list.pc-only-list,
  body .footer-area .footer-wrapper .conpany-list.pc-only-list,
  body .footer-area .footer-wrapper .news-list.pc-only-list,
  body .footer-area .footer-wrapper .sns-list.pc-only-list,
  body .footer-area .footer-wrapper .policy-en-list.pc-only-list,
  body .footer-area .footer-wrapper .policy-list.pc-only-list {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .service-list,
  body .footer-area .footer-wrapper .brand-list,
  body .footer-area .footer-wrapper .recruit-list,
  body .footer-area .footer-wrapper .marine-tech-list,
  body .footer-area .footer-wrapper .sustainability-list,
  body .footer-area .footer-wrapper .conpany-list,
  body .footer-area .footer-wrapper .news-list,
  body .footer-area .footer-wrapper .sns-list,
  body .footer-area .footer-wrapper .policy-en-list,
  body .footer-area .footer-wrapper .policy-list {
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #fff;
  }
  body .footer-area .footer-wrapper .service-list.pc-only-list,
  body .footer-area .footer-wrapper .brand-list.pc-only-list,
  body .footer-area .footer-wrapper .recruit-list.pc-only-list,
  body .footer-area .footer-wrapper .marine-tech-list.pc-only-list,
  body .footer-area .footer-wrapper .sustainability-list.pc-only-list,
  body .footer-area .footer-wrapper .conpany-list.pc-only-list,
  body .footer-area .footer-wrapper .news-list.pc-only-list,
  body .footer-area .footer-wrapper .sns-list.pc-only-list,
  body .footer-area .footer-wrapper .policy-en-list.pc-only-list,
  body .footer-area .footer-wrapper .policy-list.pc-only-list {
    display: none;
  }
}

body .footer-area .footer-wrapper .service-list.sp-only-list,
body .footer-area .footer-wrapper .brand-list.sp-only-list,
body .footer-area .footer-wrapper .recruit-list.sp-only-list,
body .footer-area .footer-wrapper .marine-tech-list.sp-only-list,
body .footer-area .footer-wrapper .sustainability-list.sp-only-list,
body .footer-area .footer-wrapper .conpany-list.sp-only-list,
body .footer-area .footer-wrapper .news-list.sp-only-list,
body .footer-area .footer-wrapper .sns-list.sp-only-list,
body .footer-area .footer-wrapper .policy-en-list.sp-only-list,
body .footer-area .footer-wrapper .policy-list.sp-only-list {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper .service-list.sp-only-list,
  body .footer-area .footer-wrapper .brand-list.sp-only-list,
  body .footer-area .footer-wrapper .recruit-list.sp-only-list,
  body .footer-area .footer-wrapper .marine-tech-list.sp-only-list,
  body .footer-area .footer-wrapper .sustainability-list.sp-only-list,
  body .footer-area .footer-wrapper .conpany-list.sp-only-list,
  body .footer-area .footer-wrapper .news-list.sp-only-list,
  body .footer-area .footer-wrapper .sns-list.sp-only-list,
  body .footer-area .footer-wrapper .policy-en-list.sp-only-list,
  body .footer-area .footer-wrapper .policy-list.sp-only-list {
    display: block;
  }
  body .footer-area .footer-wrapper .service-list.sp-only-list a,
  body .footer-area .footer-wrapper .brand-list.sp-only-list a,
  body .footer-area .footer-wrapper .recruit-list.sp-only-list a,
  body .footer-area .footer-wrapper .marine-tech-list.sp-only-list a,
  body .footer-area .footer-wrapper .sustainability-list.sp-only-list a,
  body .footer-area .footer-wrapper .conpany-list.sp-only-list a,
  body .footer-area .footer-wrapper .news-list.sp-only-list a,
  body .footer-area .footer-wrapper .sns-list.sp-only-list a,
  body .footer-area .footer-wrapper .policy-en-list.sp-only-list a,
  body .footer-area .footer-wrapper .policy-list.sp-only-list a {
    font-size: 21px;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .service-list.sp-only-list,
  body .footer-area .footer-wrapper .brand-list.sp-only-list,
  body .footer-area .footer-wrapper .recruit-list.sp-only-list,
  body .footer-area .footer-wrapper .marine-tech-list.sp-only-list,
  body .footer-area .footer-wrapper .sustainability-list.sp-only-list,
  body .footer-area .footer-wrapper .conpany-list.sp-only-list,
  body .footer-area .footer-wrapper .news-list.sp-only-list,
  body .footer-area .footer-wrapper .sns-list.sp-only-list,
  body .footer-area .footer-wrapper .policy-en-list.sp-only-list,
  body .footer-area .footer-wrapper .policy-list.sp-only-list {
    display: block;
  }
}

body .footer-area .footer-wrapper .service-list {
  width: 250px;
}

body .footer-area .footer-wrapper .brand-list {
  width: 335px;
}

body .footer-area .footer-wrapper .recruit-list {
  width: 135px;
}

body .footer-area .footer-wrapper .conpany-list,
body .footer-area .footer-wrapper .policy-list {
  width: 175px;
  margin-right: 0;
}

body .footer-area .footer-wrapper .marine-tech-list,
body .footer-area .footer-wrapper .recruit-list,
body .footer-area .footer-wrapper .sns-list,
body .footer-area .footer-wrapper .policy-list,
body .footer-area .footer-wrapper .sustainability-list {
  margin-top: 36px;
}

body .footer-area .footer-wrapper .footer-link-list {
  display: flex;
  align-items: top;
  justify-content: space-between;
  margin-top: 43px;
}

body .footer-area .footer-wrapper .footer-link-list .language {
  display: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper .footer-link-list {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    border-top: solid 1px #fff;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .footer-link-list {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;
    border-top: solid 1px #fff;
  }
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title {
  font-size: 18px;
  font-weight: bold;
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title a {
  position: relative;
  color: #fff;
  text-decoration: none;
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title a::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: #fff;
  transition: transform 0.3s;
  transform: scale(0, 1);
  transform-origin: left top;
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title a:hover::after {
  transform: scale(1, 1);
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper .footer-link-list .link-list-title {
    padding: 17px 0;
    margin-left: 20px;
    font-size: 14px;
    font-weight: normal;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .footer-link-list .link-list-title {
    padding: 17px 0;
    margin-left: 20px;
    font-size: 13px;
    font-weight: normal;
  }
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title .sub-link-list-title .language {
  margin-left: 24px;
}

body .footer-area .footer-wrapper .footer-link-list .link-list-title .sub-link-list-title .language_en {
  margin-left: 24px;
}

body .footer-area .footer-wrapper .footer-link-list .link-list {
  padding: 0;
  margin-top: 20px;
  font-size: 12px;
  list-style-type: none;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-wrapper .footer-link-list .link-list {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .footer-link-list .link-list {
    display: none;
  }
}

body .footer-area .footer-wrapper .footer-link-list .link-list li {
  margin-bottom: 12px;
}

body .footer-area .footer-wrapper .footer-link-list .link-list li a {
  color: #fff;
  text-decoration: none;
}

body .footer-area .footer-wrapper .footer-link-list .icon-list {
  display: flex;
}

body .footer-area .footer-wrapper .footer-link-list .icon-list .footer-icon {
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .footer-link-list .icon-list .footer-icon:first-child {
    margin-left: 22px;
  }
}

body .footer-area .footer-wrapper .footer-link-list .icon-list .footer-icon .sns-icon {
  margin-top: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-wrapper .footer-link-list .icon-list .footer-icon .sns-icon {
    margin: 8px 20px 0 0;
  }
}

body .footer-area .footer-copy {
  display: inherit;
  margin-top: 30px;
  font-size: 12px;
  text-align: center;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .footer-area .footer-copy {
    margin-top: 80px;
    margin-left: 20px;
    font-size: 20px;
    text-align: left;
  }
}

@media screen and (max-width: 599px) {
  body .footer-area .footer-copy {
    margin-top: 80px;
    margin-left: 20px;
    font-size: 10px;
    text-align: left;
  }
}

body .scroll-to-top {
  display: none;
}

@media screen and (max-width: 599px) {
  body .scroll-to-top {
    position: fixed;
    right: 0;
    bottom: 30px;
    z-index: 1001;
    display: block;
    width: 54px;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: #cc0a1e;
    border: solid 1px #fff;
    border-radius: 100px 100px 0 0;
  }
}

body .back-gray {
  position: fixed;
  right: -100%;
  z-index: 999;
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  body .back-gray {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: #3a3c46;
    transition: 0.35s ease-in-out;
  }
  body .back-gray.show {
    right: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .back-gray {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: #3a3c46;
    transition: 0.35s ease-in-out;
  }
  body .back-gray.show {
    right: 0;
  }
}

@media screen and (max-width: 599px) {
  body .back-gray {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: #3a3c46;
    transition: 0.35s ease-in-out;
  }
  body .back-gray.show {
    right: 0;
  }
}

@keyframes SlideIn {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translatex(50%);
  }
  50% {
    transform: translatex(0%);
  }
}

body {
  min-width: 1240px;
  color: #292929;
  background: url("../img/css-img/404_bg.png") no-repeat;
  background-color: #f3f4f5;
  background-size: contain;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body {
    width: 100%;
    min-width: 600px;
  }
}

@media screen and (max-width: 599px) {
  body {
    width: 100%;
    min-width: 300px;
    background: #f3f4f5;
  }
}

body .error-container {
  width: 100%;
  margin: 300px auto 100px;
  text-align: center;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .error-container {
    margin: 200px auto 77px;
  }
}

@media screen and (max-width: 599px) {
  body .error-container {
    margin: 153px auto 77px;
  }
}

body .error-container .error-title {
  font-size: 30px;
  letter-spacing: 0.04em;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .error-container .error-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 599px) {
  body .error-container .error-title {
    font-size: 20px;
  }
}

body .error-container .error-text {
  margin-top: 35px;
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: 0.04em;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .error-container .error-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 599px) {
  body .error-container .error-text {
    margin-top: 33px;
    font-size: 14px;
  }
}

body .error-container .error-icon {
  display: block;
  margin: 80px auto 0;
}

@media screen and (max-width: 599px) {
  body .error-container .error-icon {
    width: 154px;
    height: 100%;
  }
}

body .error-container .back-to-top {
  display: block;
  width: 380px;
  height: 64px;
  margin: 91px auto 0;
  font-size: 18px;
  line-height: 64px;
  color: #292929;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1.8px;
  background: url("../img/css-img/arrows.svg") top 50% right 32px/47px 6px no-repeat #fff;
  border: solid 1px #000;
  border-radius: 30px;
  transition: all 0.3s;
}

body .error-container .back-to-top:hover {
  color: #fff !important;
  background: url("../img/css-img/arrows_white.svg") top 50% right 32px/47px 6px no-repeat #292929 !important;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  body .error-container .back-to-top {
    font-size: 16px;
  }
}

@media screen and (max-width: 599px) {
  body .error-container .back-to-top {
    width: calc(100% - 40px);
    height: 53px;
    font-size: 16px;
    line-height: 53px;
    background: url("../img/css-img/arrows.svg") top 50% right 32px/24px 6px no-repeat #fff;
  }
  body .error-container .back-to-top:hover {
    background: url("../img/css-img/arrows_white.svg") top 50% right 32px/24px 6px no-repeat #292929 !important;
  }
}

body .section-contact {
  padding: 48px 0 55px;
  background: #fff;
}
