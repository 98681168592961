/* フォント */
@font-face {
  font-family: 'NotoSansMedium';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Medium.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansJPMedium';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Medium.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansBold';
  font-style: normal;
  src: url(../../../common/font/NotoSans-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'NotoSansExtraBold';
  font-style: normal;
  src: url(../../../common/font/NotoSansCJKjp-Black.woff) format('woff');
}
