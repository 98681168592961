@import './breakpoint.scss';
@import './fontface.scss';
@import './variable.scss';

/*
 * 共通コンポーネントCSS
 */
* {
  padding: 0;
  margin: 0;
  font-family: 'NotoSansMedium', sans-serif;
  outline: none !important;
}

/*　アニメーション */
.text-link,
.text-link-about,
.text-link-sustainability,
.text-link-solution {
  position: relative;
  display: inline-block;
  text-decoration: none;
  &::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    content: '';
    background: #333;
    transition: transform 0.3s;
    transform: scale(0, 1);
    transform-origin: left top;
  }
  &:hover::after {
    transform: scale(1, 1);
  }
  &.selected {
    &::after {
      transform: scale(1, 1);
    }
  }
}
/* 通常フッター */
.footer-area {
  .footer-wrapper {
    .text-link {
      position: relative;
      display: inline-block;
      text-decoration: none;
      &.external-link {
        display: inline-flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        &::before {
          width: 14px;
          height: 14px;
          margin-top: 3px;
          margin-left: 5px;
          content: '';
          background: url('../img/css-img/clone_white.svg') 0 0 / 14px 14px no-repeat;
        }
      }
      &::after {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background: #fff;
        transition: transform 0.3s;
        transform: scale(0, 1);
        transform-origin: left top;
      }

      &:hover::after {
        transform: scale(1, 1);
      }
    }
  }
}
.button {
  position: relative;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s;
  @media all and (-ms-high-contrast: none) {
    // IE対策
    padding-top: 3px;
  }
}

body {
  min-width: 1240px;
  @include tb() {
    min-width: 0;
  }
  @include sp() {
    min-width: 0;
  }
  /* 改行 */
  .br-sp {
    display: none;
  }
  .br-tb {
    display: none;
  }
  @include tb() {
    .br-pc {
      display: none;
    }
    .br-tb {
      display: block;
    }
    .br-sp {
      display: none;
    }
  }
  @include sp() {
    .br-sp {
      display: block;
    }
    .br-tb {
      display: none;
    }
    .br-pc {
      display: none;
    }
  }
  // IE用全体スタイル
  hr {
    text-align: left;
  }

  /* ヘッダー */
  .header-area {
    position: fixed;
    top: 0;
    z-index: 20;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 107px;
    margin: 0 auto;
    list-style: none;
    transition: all 0.1s ease-out 0.1s;
    @include header() {
      z-index: 1000;
      flex-wrap: nowrap;
      height: 65px;
      padding: 0 0 0 40px;
      background: #fff;
    }
    @include tb() {
      z-index: 1000;
      flex-wrap: nowrap;
      height: 65px;
      padding: 0 0 0 40px;
      background: #fff;
    }
    @include sp() {
      z-index: 1000;
      flex-wrap: nowrap;
      height: 54px;
      padding: 0 0 0 20px;
      background: #fff;
    }
    .normal-logo {
      display: block;
    }
    .white-logo {
      display: none;
    }
    &.jsBackgroundHeader {
      background: #fff;
      @include header() {
        .content-nav-sp {
          .sp-nav-button {
            background: $main-color;
            .nav-toggle {
              i {
                background-color: #fff;
              }
            }
          }
        }
      }
      @include tb() {
        .content-nav-sp {
          .sp-nav-button {
            background: $main-color;
            .nav-toggle {
              i {
                background-color: #fff;
              }
            }
          }
        }
      }
      @include sp() {
        .content-nav-sp {
          .sp-nav-button {
            background: $main-color;
            .nav-toggle {
              i {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .header-logo {
      width: 181px;
      margin-left: 40px;
      // 英語にもグロナビが反映されたとき用
      // @media screen and (max-width: 1400px) {
      //   margin-left: 40px;
      // }
      @include tb() {
        margin-left: 0;
      }
      @include sp() {
        margin-left: 0;
      }
      .header-logo-img {
        width: 181px;
        @include tb() {
          width: 166px;
        }
        @include sp() {
          width: 138px;
        }
      }
    }
    .content-nav {
      display: flex;
      align-items: center;
      min-width: 800px;
      font-size: 14px;
      .language-atention {
        position: relative;
        left: -12px;
        display: none;
        width: 277px;
        height: 41px;
        margin-top: 10px;
        font-size: 12px;
        line-height: 41px;
        text-align: center;
        background: #fff;
        border: 1px solid #c3c3c3;
      }
      .header-content {
        display: flex;
        align-items: center;
        list-style: none;
        .select-content {
          padding: 0 20px;
          line-height: 35px;
          cursor: pointer;
          @media screen and (max-width: 1320px) {
            padding: 0 12px;
          }
          &:last-of-type {
            padding-right: 40px;
          }
          .head-content {
            color: $font-color;
            text-decoration: none;
          }
          .language {
            display: none;
          }
          .language_en {
            display: block;
          }
        }
      }
      .contact-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 126px;
        height: 35px;
        margin: 0 auto;
        margin-right: 40px;
        font-size: 14px;
        line-height: 35px;
        color: #fff;
        text-decoration: none;
        background: #cc0a1e;
        border: 1px solid #cc0a1e;
        border-radius: 100px;
        &:hover {
          color: #cc0a1e;
          background: #fff;
        }
        @media all and (-ms-high-contrast: none) {
          // IE対策
          padding-top: 3px;
        }
        // @media screen and (max-width: 1400px) {
        //   margin-right: 40px;
        // }
      }
      @include header() {
        display: none;
      }
      @include tb() {
        display: none;
      }
      @include sp() {
        display: none;
      }
      .language-btn-area {
        display: flex;
        flex-direction: column;
        width: 127px;
        height: 35px;
        padding: 0 12px 0 30px;
        margin: 36px 0;
        cursor: pointer;
        border-left: 2px solid #292929;
      }
      .language-menu {
        position: relative;
        width: 127px;
        line-height: 35px;
        text-align: center;
        &::before {
          position: absolute;
          top: 9px;
          left: 6px;
          display: block;
          width: 20px;
          height: 20px;
          content: '';
          background: url('../img/css-img/icon_language.svg') top right / 20px 20px no-repeat;
        }
        &::after {
          position: absolute;
          top: calc(50% - 5px);
          right: 15px;
          display: block;
          width: 6px;
          height: 6px;
          content: '';
          border-right: 2px solid #292929;
          border-bottom: 2px solid #292929;
          transform: rotate(45deg);
        }
      }
      .dropdown__lists {
        display: none;
      }
      .dropdown__lists.active {
        display: block;
        width: 127px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 11px 18px -4px rgba(0, 0, 0, 0.3);
      }
      .dropdown__list {
        position: relative;
        height: 30px;
        list-style-type: none;
        transition: all 0.3s;
      }
      .dropdown__list a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 30px;
        color: #292929;
        text-decoration: none;
        border-radius: 8px;
        transition: all 0.3s;
      }
      a.active:hover {
        color: #cc0a1e;
      }
      .dropdown__list .check::before {
        position: absolute;
        top: 9px;
        left: 6px;
        display: block;
        width: 16px;
        height: 16px;
        content: '';
        background: url('../img/css-img/icon_check.svg') top right / 16px 16px no-repeat;
      }
    }
    .Header-backdrop-about {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 107px;
      pointer-events: none;
      visibility: hidden;
      background-color: #00000067;
      opacity: 0;
      transition: 0.3s ease-out;
      &.Header-backdrop_visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .Header-backdrop-sustainability {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 107px;
      pointer-events: none;
      visibility: hidden;
      background-color: #00000067;
      opacity: 0;
      transition: 0.3s ease-out;
      &.Header-backdrop_visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .Header-backdrop-solution {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-top: 107px;
      pointer-events: none;
      visibility: hidden;
      background-color: #00000067;
      opacity: 0;
      transition: 0.3s ease-out;
      &.Header-backdrop_visible {
        visibility: visible;
        opacity: 1;
      }
    }
    .dropdown_area.dropdown-about_hierarchy {
      display: none;
      &.active {
        position: absolute;
        left: 50%;
        display: block;
        width: 100%;
        height: 330px;
        transition: all 0.5s;
        transform: translateX(-50%);
        animation: fade-in 0.5s;
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .dropdown_area_white {
          position: absolute;
          top: 55px;
          left: 50%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          padding: 44px 48px 39px;
          background: #fff;
          border-radius: 30px;
          transition: all 0.5s;
          transform: translateX(-50%);
        }
        .head-content_lists {
          display: flex;
          height: 100%;
          padding: 0;
          font-size: 14px;
          .head-content_list {
            width: 208px;
            margin-right: 32px;
            color: #292929;
            list-style-type: none;
            &:nth-child(5) {
              margin-right: 0;
            }
            &:nth-child(2) {
              .text-link-list {
                .head-content-img {
                  border: 1px solid #c3c3c3;
                }
              }
            }
            .text-link-list {
              display: block;
              text-decoration: none;
              .head-content-img {
                width: 208px;
                height: 152px;
                vertical-align: bottom;
                border-radius: 20px;
              }
              .head-content-text {
                margin-top: 16px;
                font-size: 14px;
                line-height: 1.36;
                color: #292929;
                text-align: left;
                text-decoration: none;
              }
              &:hover {
                opacity: 0.5;
                transition: all 0.3s;
              }
            }
          }
        }
      }
    }
    .dropdown_area.dropdown-sustainability_hierarchy {
      display: none;
      &.active {
        position: absolute;
        left: 50%;
        display: block;
        width: 100%;
        height: 330px;
        cursor: default;
        transition: all 0.5s;
        transform: translateX(-50%);
        animation: fade-in 0.5s;
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .dropdown_area_white {
          position: absolute;
          top: 55px;
          left: 50%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          padding: 44px 48px 39px;
          background: #fff;
          border-radius: 30px;
          transition: all 0.5s;
          transform: translateX(-50%);
        }
        .head-content_lists {
          display: flex;
          height: 100%;
          padding: 0;
          font-size: 14px;
          .head-content_list {
            width: 208px;
            margin-right: 32px;
            color: #292929;
            list-style-type: none;
            &:nth-child(2) {
              margin-right: 0;
            }
            .text-link-list {
              display: block;
              text-decoration: none;
              .head-content-img {
                width: 208px;
                height: 152px;
                vertical-align: bottom;
                border-radius: 20px;
                &.border {
                  border: 1px solid #c3c3c3;
                }
              }
              .head-content-text {
                margin-top: 16px;
                font-size: 14px;
                line-height: 1.36;
                color: #292929;
                text-align: left;
                text-decoration: none;
                letter-spacing: -0.15px;
              }
              &:hover {
                opacity: 0.5;
                transition: all 0.3s;
              }
            }
          }
        }
      }
    }
    .dropdown_area.dropdown-solution_hierarchy {
      display: none;
      &.active {
        position: absolute;
        left: 50%;
        display: block;
        width: 100%;
        height: 330px;
        cursor: default;
        transition: all 0.5s;
        transform: translateX(-50%);
        animation: fade-in 0.5s;
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        .dropdown_area_white {
          position: absolute;
          top: 55px;
          left: 50%;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          padding: 44px 48px 39px;
          background: #fff;
          border-radius: 30px;
          transition: all 0.5s;
          transform: translateX(-50%);
        }
        .head-content_lists {
          display: flex;
          height: 100%;
          padding: 0;
          font-size: 14px;
          .head-content_list {
            width: 208px;
            margin-right: 32px;
            color: #292929;
            list-style-type: none;
            &:nth-child(3) {
              margin-right: 0;
            }
            .text-link-list {
              display: block;
              text-decoration: none;
              .head-content-img {
                width: 208px;
                height: 152px;
                vertical-align: bottom;
                border-radius: 20px;
                &.border {
                  border: 1px solid #c3c3c3;
                }
              }
              .head-content-text {
                margin-top: 16px;
                font-size: 14px;
                line-height: 1.36;
                color: #292929;
                text-align: left;
                text-decoration: none;
              }
              &:hover {
                opacity: 0.5;
                transition: all 0.3s;
              }
            }
          }
        }
      }
    }
    .content-nav-sp {
      display: none;
    }
    .language-btn-area-sp.show {
      @include tb() {
        display: none;
      }
      @include sp() {
        display: none;
      }
    }
    .language-btn-area-sp {
      display: none;
      @include tb() {
        display: flex;
        flex-direction: column;
        width: 110px;
        height: 54px;
        margin-left: auto;
        font-size: 12px;
        text-align: center;
      }
      @include sp() {
        display: flex;
        flex-direction: column;
        width: 110px;
        height: 54px;
        margin-left: auto;
        font-size: 12px;
        text-align: center;
      }
      .language-menu {
        position: relative;
        line-height: 54px;
        text-align: center;
        &::before {
          position: absolute;
          top: 20px;
          display: block;
          width: 16px;
          height: 16px;
          content: '';
          background: url('../img/css-img/icon_language.svg') top right / 16px 16px no-repeat;
        }
        &::after {
          position: absolute;
          top: calc(50% - 5px);
          right: 5px;
          display: block;
          width: 6px;
          height: 6px;
          content: '';
          border-right: 2px solid #292929;
          border-bottom: 2px solid #292929;
          transform: rotate(45deg);
        }
      }
      .dropdown__lists {
        display: none;
      }
      .dropdown__lists.active {
        display: block;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 11px 18px -4px rgba(0, 0, 0, 0.3);
      }
      .dropdown__list {
        position: relative;
        height: 36px;
        list-style-type: none;
        transition: all 0.3s;
      }
      .dropdown__list a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 36px;
        color: #292929;
        text-decoration: none;
        border-radius: 8px;
        transition: all 0.3s;
      }
      a.active:hover {
        color: #cc0a1e;
      }
      .dropdown__list .check::before {
        position: absolute;
        top: 9px;
        left: 6px;
        display: block;
        width: 16px;
        height: 16px;
        content: '';
        background: url('../img/css-img/icon_check.svg') top right / 16px 16px no-repeat;
      }
      .language-atention {
        position: relative;
        left: -12px;
        display: none;
        width: 145px;
        padding: 7px;
        margin-top: 7px;
        font-size: 12px;
        text-align: left;
        background: #fff;
        border: 1px solid #c3c3c3;
      }
    }
    @include header() {
      .content-nav-sp {
        position: relative;
        display: block;
        cursor: pointer;
        .sp-nav-button {
          display: relative;
          width: 65px;
          height: 65px;
          transition: all 0.1s ease-out 0.1s;
          .nav-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 19px;
            height: 15px;
            transform: translate(-50%, -50%);
            i {
              position: absolute;
              display: block;
              width: 100%;
              height: 2px;
              background-color: #707070;
              border-radius: 2px;
              transition: transform 0.5s, opacity 0.5s;
            }
            i:nth-child(1) {
              top: 0;
            }
            i:nth-child(2) {
              top: 0;
              bottom: 0;
              margin: auto;
            }
            i:nth-child(3) {
              bottom: 0;
            }
            &.show i:nth-child(1) {
              transform: translateY(5px) rotate(-45deg);
            }
            &.show i:nth-child(2) {
              opacity: 0;
            }
            &.show i:nth-child(3) {
              transform: translateY(-8px) rotate(45deg);
            }
          }
        }
        .sp-nav {
          position: fixed;
          top: 46px;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.5s, visibility 0.5s;
          &.show {
            visibility: visible;
            opacity: 1;
          }
          .sp-nav-list {
            position: fixed;
            top: 65px;
            left: 50%;
            z-index: 1001;
            width: 100%;
            height: 336px;
            overflow: scroll;
            transform: translateX(-50%);
            .sp-nav-content {
              z-index: 1001;
              width: 100%;
              border-bottom: solid 1px #fff;
              .sp-nav-content-link {
                position: relative;
                display: block;
                padding-left: 19px;
                font-size: 15px;
                font-weight: bold;
                line-height: 44px;
                color: #fff;
                text-decoration: none;
                span {
                  font-size: 15px;
                }
                .language {
                  display: none;
                }
                .language_en {
                  display: block;
                }
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.sub-menu {
                  margin-left: 28px;
                  font-weight: normal;
                }
              }
              &.current {
                .sp-nav-content-link {
                  &::before {
                    display: block;
                  }
                }
              }
              .sp-header-menu-about {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-about.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-about {
                display: none;
              }
              .header-menu_lists-about.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-sustainability {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-sustainability.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-sustainability {
                display: none;
              }
              .header-menu_lists-sustainability.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-solution {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  margin-top: 2px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-solution.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-solution {
                display: none;
              }
              .header-menu_lists-solution.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .header-menu_list {
                list-style-type: none;
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.current {
                  .sp-nav-content-link {
                    &::before {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
        .contact-btn {
          position: fixed;
          top: 430px;
          left: 50%;
          z-index: 1001;
          width: calc(100% - 30px);
          max-width: 208px;
          height: 34px;
          font-size: 11px;
          font-weight: bold;
          line-height: 32px;
          color: #fff;
          text-align: center;
          text-decoration: none;
          background: #cc0a1e;
          border-radius: 17px;
          transform: translateX(-50%);
        }
      }
      .language {
        display: none;
      }
      .language_en {
        display: block;
      }
    }
    @include tb() {
      .content-nav-sp {
        position: relative;
        display: block;
        cursor: pointer;
        .sp-nav-button {
          display: relative;
          width: 65px;
          height: 65px;
          transition: all 0.1s ease-out 0.1s;
          .nav-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 19px;
            height: 15px;
            transform: translate(-50%, -50%);
            i {
              position: absolute;
              display: block;
              width: 100%;
              height: 2px;
              background-color: #707070;
              border-radius: 2px;
              transition: transform 0.5s, opacity 0.5s;
            }
            i:nth-child(1) {
              top: 0;
            }
            i:nth-child(2) {
              top: 0;
              bottom: 0;
              margin: auto;
            }
            i:nth-child(3) {
              bottom: 0;
            }
            &.show i:nth-child(1) {
              transform: translateY(5px) rotate(-45deg);
            }
            &.show i:nth-child(2) {
              opacity: 0;
            }
            &.show i:nth-child(3) {
              transform: translateY(-8px) rotate(45deg);
            }
          }
        }
        .sp-nav {
          position: fixed;
          top: 46px;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.5s, visibility 0.5s;
          &.show {
            visibility: visible;
            opacity: 1;
          }
          .sp-nav-list {
            position: fixed;
            top: 65px;
            left: 50%;
            width: 100%;
            height: 336px;
            overflow: scroll;
            transform: translateX(-50%);
            .sp-nav-content {
              width: 100%;
              border-bottom: solid 1px #fff;
              .sp-nav-content-link {
                position: relative;
                display: block;
                padding-left: 19px;
                font-size: 15px;
                font-weight: bold;
                line-height: 44px;
                color: #fff;
                text-decoration: none;
                span {
                  font-size: 15px;
                }
                .language {
                  display: none;
                }
                .language_en {
                  display: block;
                }
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.sub-menu {
                  margin-left: 28px;
                  font-weight: normal;
                }
              }
              &.current {
                .sp-nav-content-link {
                  &::before {
                    display: block;
                  }
                }
              }
              .sp-header-menu-about {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-about.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-about {
                display: none;
              }
              .header-menu_lists-about.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-sustainability {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-sustainability.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-sustainability {
                display: none;
              }
              .header-menu_lists-sustainability.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-solution {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  margin-top: 2px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-solution.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-solution {
                display: none;
              }
              .header-menu_lists-solution.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .header-menu_list {
                list-style-type: none;
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.current {
                  .sp-nav-content-link {
                    &::before {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
        .contact-btn {
          position: fixed;
          top: 430px;
          left: 50%;
          width: calc(100% - 30px);
          max-width: 208px;
          height: 34px;
          font-size: 11px;
          font-weight: bold;
          line-height: 32px;
          color: #fff;
          text-align: center;
          text-decoration: none;
          background: #cc0a1e;
          border-radius: 17px;
          transform: translateX(-50%);
        }
      }
      .language {
        display: none;
      }
      .language_en {
        display: block;
      }
    }
    @include sp() {
      .content-nav-sp {
        position: relative;
        display: block;
        cursor: pointer;
        .sp-nav-button {
          display: relative;
          width: 54px;
          height: 54px;
          transition: all 0.1s ease-out 0.1s;
          .nav-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 17px;
            height: 14px;
            transform: translate(-50%, -50%);
            i {
              position: absolute;
              display: block;
              width: 100%;
              height: 2px;
              background-color: #707070;
              border-radius: 2px;
              transition: transform 0.5s, opacity 0.5s;
            }
            i:nth-child(1) {
              top: 0;
            }
            i:nth-child(2) {
              top: 0;
              bottom: 0;
              margin: auto;
            }
            i:nth-child(3) {
              bottom: 0;
            }
            &.show i:nth-child(1) {
              transform: translateY(6px) rotate(-45deg);
            }
            &.show i:nth-child(2) {
              opacity: 0;
            }
            &.show i:nth-child(3) {
              transform: translateY(-6px) rotate(45deg);
            }
          }
        }
        .sp-nav {
          position: fixed;
          top: 46px;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.5s, visibility 0.5s;
          &.show {
            visibility: visible;
            opacity: 1;
          }
          .sp-nav-list {
            position: fixed;
            top: 54px;
            left: 50%;
            width: 100%;
            height: 336px;
            overflow: scroll;
            transform: translateX(-50%);
            .sp-nav-content {
              width: 100%;
              border-bottom: solid 1px #fff;
              .sp-nav-content-link {
                position: relative;
                display: block;
                padding: 12px 0 12px 19px;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                text-decoration: none;
                span {
                  font-size: 15px;
                }
                .language {
                  display: none;
                }
                .language_en {
                  display: block;
                }
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.sub-menu {
                  margin-left: 28px;
                  font-weight: normal;
                }
              }
              &.current {
                .sp-nav-content-link {
                  &::before {
                    display: block;
                  }
                }
              }
              .sp-header-menu-about {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-about.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-about {
                display: none;
              }
              .header-menu_lists-about.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-sustainability {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-sustainability.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-sustainability {
                display: none;
              }
              .header-menu_lists-sustainability.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .sp-header-menu-solution {
                &::after {
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 40px;
                  display: block;
                  width: 6px;
                  height: 6px;
                  margin-top: 2px;
                  content: '';
                  border-right: 2px solid #fff;
                  border-bottom: 2px solid #fff;
                  transform: rotate(45deg);
                }
              }
              .sp-header-menu-solution.check {
                &::after {
                  margin-top: 2px;
                  transform: rotate(225deg);
                  animation: fade-in 0.5s;
                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                }
              }
              .header-menu_lists-solution {
                display: none;
              }
              .header-menu_lists-solution.active {
                display: block;
                animation: fade-in 0.5s;
                @keyframes fade-in {
                  0% {
                    opacity: 0;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              }
              .header-menu_list {
                list-style-type: none;
                &::before {
                  position: absolute;
                  top: 9px;
                  left: 6px;
                  display: none;
                  width: 4px;
                  height: 28px;
                  content: '';
                  background: #fff;
                  border-radius: 3px;
                }
                &.current {
                  .sp-nav-content-link {
                    &::before {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
        .contact-btn {
          position: fixed;
          top: 420px;
          left: 50%;
          width: calc(100% - 40px);
          height: 34px;
          font-size: 11px;
          font-weight: bold;
          line-height: 32px;
          color: #fff;
          text-align: center;
          text-decoration: none;
          background: #cc0a1e;
          border-radius: 17px;
          transform: translateX(-50%);
        }
      }
      .language {
        display: none;
      }
      .language_en {
        display: block;
      }
    }
  }

  /* パンくずリスト */
  .topic-path {
    height: 81px;
    padding: 0 0 0 237px;
    line-height: 81px;
    background: #dfe0e2;
    @include tb() {
      height: 100%;
      padding: 0 0 0 20px;
      line-height: 72px;
    }
    @include sp() {
      height: 100%;
      min-height: 40px;
      padding: 0 15px 0 20px;
      line-height: 40px;
    }
    ul {
      display: flex;
      padding: 0;
      list-style-type: none;
      @include tb() {
        flex-wrap: wrap;
      }
      @include sp() {
        flex-wrap: wrap;
      }
      .topic-list {
        @include tb {
          font-size: 18px;
        }
        @include sp() {
          height: auto;
          min-height: 40px;
          font-size: 13px;
          line-height: 40px;
        }
        & + .topic-list {
          &::before {
            display: inline-block;
            margin: 0 12px;
            content: '▶';
          }
        }
        .topic-list-child {
          color: $font-color;
          text-decoration: none;
          letter-spacing: 1px;
          @include tb() {
            height: 40px;
            font-size: 18px;
          }
          @include sp() {
            letter-spacing: 0.1px;
          }
        }
      }
    }
  }

  /* コンタクト */
  .child-contact-wrapper {
    width: 1080px;
    margin: 0 auto;
    @include tb() {
      width: 100%;
      text-align: center;
    }
    @include sp() {
      width: 100%;
      text-align: center;
    }
    .main-title {
      font-size: 45px;
      font-weight: bold;
      text-align: center;
      hr {
        width: 98px;
        height: 8px;
        margin: 10px auto 0;
        background: $font-color;
        border: none;
      }
      @include sp() {
        margin: 0;
        font-size: 32px;
        hr {
          height: 4px;
          margin: 4px auto 0;
        }
      }
    }
    .contact-contents {
      margin: 43px auto 0;
      text-align: center;
      @include sp() {
        width: 100%;
        margin: 29px auto 0;
      }
      .contact-contents-details {
        margin-top: 5px;
        font-size: 18px;
        line-height: 1.8;
        color: #3c3c3c;
        letter-spacing: 2.88px;
        @include sp() {
          display: none;
        }
      }
      .contact-contents-details-sp {
        display: none;
        @include sp() {
          display: flex;
          justify-content: center;
          margin: 21px auto 0;
          font-size: 14px;
          line-height: 2;
          color: $font-color;
          text-align: left;
          letter-spacing: 0.56px;
        }
      }
      .next-contact {
        display: block;
        width: 432px;
        height: 68px;
        margin: 23px auto 0;
        font-size: 20px;
        font-weight: bold;
        line-height: 68px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        letter-spacing: 2px;
        background: url('../img/css-img/arrows_white.svg') top 50% right 64px no-repeat $main-color;
        border-radius: 60px;
        &:hover {
          color: $main-color;
          background: url('../img/css-img/arrows_red.svg') top 50% right 64px no-repeat #fff;
          border: solid 1px $main-color;
        }
        @include sp() {
          width: calc(100% - 80px);
          height: 48px;
          margin: 30px auto 0;
          font-size: 16px;
          line-height: 48px;
          background: url('../img/css-img/arrows_white.svg') top 50% right 36px / 24px 6px no-repeat $main-color;
          &:hover {
            color: $main-color;
            background: url('../img/css-img/arrows_red.svg') top 50% right 36px / 24px 6px no-repeat #fff;
            border: solid 1px $main-color;
          }
        }
      }
      .language {
        display: none;
      }
      .language_en {
        display: block;
        margin-bottom: 43px;
        font-size: 18px;
        text-align: center;
        letter-spacing: 2.88px;
        @include tb() {
          width: auto;
        }
        @include sp() {
          width: auto;
          padding: 0px 10px;
          margin-bottom: 0px;
        }
      }
      .language_en-btn {
        display: block;
        font-size: 18px;
        text-align: center;
        letter-spacing: 0.4px;
        white-space: nowrap;
      }
    }
  }

  /* フッター */
  .footer-area {
    padding: 81px 0 10px;
    color: #fff;
    background: #1b1e24;
    @include tb() {
      height: auto;
      padding: 36px 20px;
    }
    @include sp() {
      height: auto;
      padding: 36px 20px;
    }
    .footer-wrapper {
      width: 1080px;
      margin: 0 auto;
      .footer-logo {
        width: 180px;
        transition: all 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
      @include tb() {
        width: 100%;
        .footer-logo {
          width: 369px;
          margin-left: 20px;
        }
      }
      @include sp() {
        width: 100%;
        .footer-logo {
          width: 180px;
          margin-left: 20px;
        }
      }
      .service-list,
      .brand-list,
      .recruit-list,
      .marine-tech-list,
      .sustainability-list,
      .conpany-list,
      .news-list,
      .sns-list,
      .policy-en-list,
      .policy-list {
        width: 182px;
        @include tb() {
          width: 100%;
          margin: 0;
          border-bottom: 1px solid #fff;
          &.pc-only-list {
            display: none;
          }
        }
        @include sp() {
          width: 100%;
          margin: 0;
          border-bottom: 1px solid #fff;
          &.pc-only-list {
            display: none;
          }
        }
        &.sp-only-list {
          display: none;
          @include tb() {
            display: block;
            a {
              font-size: 21px;
            }
          }
          @include sp() {
            display: block;
          }
        }
      }
      .service-list {
        width: 250px;
      }
      .brand-list {
        width: 335px;
      }
      .recruit-list {
        width: 135px;
      }
      .conpany-list,
      .policy-list {
        width: 175px;
        margin-right: 0;
      }
      .marine-tech-list,
      .recruit-list,
      .sns-list,
      .policy-list,
      .sustainability-list {
        margin-top: 36px;
      }
      .footer-link-list {
        display: flex;
        align-items: top;
        justify-content: space-between;
        margin-top: 43px;
        .language {
          display: none;
        }
        @include tb() {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 16px;
          border-top: solid 1px #fff;
        }
        @include sp() {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 16px;
          border-top: solid 1px #fff;
        }
        .link-list-title {
          font-size: 18px;
          font-weight: bold;
          a {
            position: relative;
            color: #fff;
            text-decoration: none;
            &::after {
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              content: '';
              background: #fff;
              transition: transform 0.3s;
              transform: scale(0, 1);
              transform-origin: left top;
            }

            &:hover::after {
              transform: scale(1, 1);
            }
          }
          @include tb() {
            padding: 17px 0;
            margin-left: 20px;
            font-size: 14px;
            font-weight: normal;
          }
          @include sp() {
            padding: 17px 0;
            margin-left: 20px;
            font-size: 13px;
            font-weight: normal;
          }
          .sub-link-list-title {
            .language {
              margin-left: 24px;
            }
            .language_en {
              margin-left: 24px;
            }
          }
        }
        .link-list {
          padding: 0;
          margin-top: 20px;
          font-size: 12px;
          list-style-type: none;
          @include tb() {
            display: none;
          }
          @include sp() {
            display: none;
          }
          li {
            margin-bottom: 12px;
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
        .icon-list {
          display: flex;
          .footer-icon {
            text-decoration: none;
            @include sp() {
              &:first-child {
                margin-left: 22px;
              }
            }
            .sns-icon {
              margin-top: 20px;
              margin-right: 20px;
              @include sp() {
                margin: 8px 20px 0 0;
              }
            }
          }
        }
      }
    }
    .footer-copy {
      display: inherit;
      margin-top: 30px;
      font-size: 12px;
      text-align: center;
      @include tb() {
        margin-top: 80px;
        margin-left: 20px;
        font-size: 20px;
        text-align: left;
      }
      @include sp() {
        margin-top: 80px;
        margin-left: 20px;
        font-size: 10px;
        text-align: left;
      }
    }
  }

  .scroll-to-top {
    display: none;
    @include sp() {
      position: fixed;
      right: 0;
      bottom: 30px;
      z-index: 1001;
      display: block;
      width: 54px;
      height: 36px;
      font-size: 14px;
      font-weight: bold;
      line-height: 36px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background: #cc0a1e;
      border: solid 1px #fff;
      border-radius: 100px 100px 0 0;
    }
  }

  .back-gray {
    position: fixed;
    right: -100%;
    z-index: 999;
  }
  @include header() {
    .back-gray {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      background: #3a3c46;
      transition: 0.35s ease-in-out;
      &.show {
        right: 0;
      }
    }
  }
  @include tb() {
    .back-gray {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      background: #3a3c46;
      transition: 0.35s ease-in-out;
      &.show {
        right: 0;
      }
    }
  }
  @include sp() {
    .back-gray {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      background: #3a3c46;
      transition: 0.35s ease-in-out;
      &.show {
        right: 0;
      }
    }
  }

  @keyframes SlideIn {
    0% {
      transform: translateX(100%);
    }
    50% {
      transform: translatex(50%);
    }
    50% {
      transform: translatex(0%);
    }
  }
}
