// プラグイン読み込み
@import '~slick-carousel/slick/slick.scss';
// 以下、自身で作成したscss読込
@import '../../../common/scss/style.scss';

body {
  min-width: 1240px;
  color: $font-color;
  background: url('../img/css-img/404_bg.png') no-repeat;
  background-color: #f3f4f5;
  background-size: contain;
  @include tb() {
    width: 100%;
    min-width: 600px;
  }
  @include sp() {
    width: 100%;
    min-width: 300px;
    background: #f3f4f5;
  }

  .error-container {
    width: 100%;
    margin: 300px auto 100px;
    text-align: center;
    @include tb() {
      margin: 200px auto 77px;
    }
    @include sp() {
      margin: 153px auto 77px;
    }
    .error-title {
      font-size: 30px;
      letter-spacing: 0.04em;
      @include tb() {
        font-size: 20px;
      }
      @include sp() {
        font-size: 20px;
      }
    }
    .error-text {
      margin-top: 35px;
      font-size: 20px;
      line-height: 1.8;
      letter-spacing: 0.04em;
      @include tb() {
        font-size: 14px;
      }
      @include sp() {
        margin-top: 33px;
        font-size: 14px;
      }
    }
    .error-icon {
      display: block;
      margin: 80px auto 0;
      @include sp() {
        width: 154px;
        height: 100%;
      }
    }
    .back-to-top {
      display: block;
      width: 380px;
      height: 64px;
      margin: 91px auto 0;
      font-size: 18px;
      line-height: 64px;
      color: $font-color;
      text-align: center;
      text-decoration: none;
      letter-spacing: 1.8px;
      background: url('../img/css-img/arrows.svg') top 50% right 32px / 47px 6px no-repeat #fff;
      border: solid 1px #000;
      border-radius: 30px;
      transition: all 0.3s;
      &:hover {
        color: #fff !important;
        background: url('../img/css-img/arrows_white.svg') top 50% right 32px / 47px 6px no-repeat $font-color !important;
      }
      @include tb() {
        font-size: 16px;
      }
      @include sp() {
        width: calc(100% - 40px);
        height: 53px;
        font-size: 16px;
        line-height: 53px;
        background: url('../img/css-img/arrows.svg') top 50% right 32px / 24px 6px no-repeat #fff;
        &:hover {
          background: url('../img/css-img/arrows_white.svg') top 50% right 32px / 24px 6px no-repeat $font-color !important;
        }
      }
    }
  }
  .section-contact {
    padding: 48px 0 55px;
    background: #fff;
  }
}
