@charset 'utf-8';

$breakpoints: (
  'sp': 'screen and (max-width: 599px)',
  'tb': 'screen and (min-width: 600px) and (max-width: 1024px)',
  'pc': 'screen and (min-width: 1025px) and (max-width: 1499px)',
  'pc-xl': 'screen and (min-width: 1500px)',
  'header': 'screen and (min-width: 1024px) and (max-width: 1230px)',
) !default;

@mixin sp($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin tb($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin pc($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin pc-xl($breakpoint: pc-xl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// グローバルナビ用
@mixin header($breakpoint: header) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
